import { useEffect } from "react";
import { navigate } from "gatsby";

const NotFound = () => {
  // Redirect to Home if a 404 is encountered.
  useEffect(() => {
      navigate(
        "/",
        { replace: true }
      )
  }, []);

  return null;
};

export default NotFound;
